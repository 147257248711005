import React from "react";
import New_Cin from "../images/2024_CIN_COVER_web.jpg";
import Ohio from "../images/2025_COL_COVER_page.jpg";
import Indiana from "../images/indiana.jpg";
import dayton from "../images/dayton.jpg";
import Cincinatti from "../images/Cincinnati21.pdf";
import Columbus from "../images/Columbus24.pdf";
import Dayton from "../images/Dayton21.pdf";
import Indianapolis from "../images/Indianapolis21.pdf";
import { Link } from "gatsby";
import PrintDistribution from "./printDistribution";

export default function Directory() {
  // var QRCode = require("qrcode.react")
  const downloadFunc = (pdf) => {
    let link = document.createElement("a");
    link.href = pdf;
    link.setAttribute("download", "locations.pdf");
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div>
      <main>
        <section className="directory-banner">
          <h1
            className="text-center mb-0"
            style={{
              color: `#005D9D`,
              fontFamily: `Ubuntu Bold`,
              fontSize: `55px`,
              fontWeight: `700`,
            }}
          >
            Christian Business Directory
          </h1>
        </section>
        <section className="new-directory">
          <div>
            <div className="row justify-content-center pt-5 mx-3">
              <div className="col-md-6 mb-5 mb-md-5 magazine-column">
                <div className="row">
                  <div className="col-xl-5 directory-img-col">
                    <div className="directory-img">
                      <Link to="/printdirectory/greater-cincinnati-northern-kentucky">
                        <img
                          src={New_Cin}
                          alt="Magazine Cover"
                          className="img-fluid magazine"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-7 heading-print-directory">
                    <Link to="/printdirectory/greater-cincinnati-northern-kentucky">
                      <h3 className="mb-2 mt-2 mt-xl-0 magazine-column-head magazine-col-directory">
                        Greater Cincinnati / Northern Kentucky
                      </h3>
                    </Link>
                    <h5>
                      Businesses & Deals from the Greater Cincinnati & Northern
                      Kentucky Area
                    </h5>
                    <div className="mb-3">
                      <button
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                          background: `transparent`,
                          border: `none`,
                        }}
                        type="button"
                        onClick={() => {
                          downloadFunc(Cincinatti);
                        }}
                      >
                        Find a Copy
                      </button>
                      <Link
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                        }}
                        to="/printdirectory/greater-cincinnati-northern-kentucky"
                        className="pl-5"
                        rel="noreferrer noopener"
                      >
                        View Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-5 mb-md-5 magazine-column">
                <div className="row">
                  <div className="col-xl-5 directory-img-col">
                    <div className="directory-img">
                      <Link to="/printdirectory/columbus-and-central-ohio">
                        <img
                          src={Ohio}
                          alt="Magazine Cover"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-7 heading-print-directory">
                    <Link to="/printdirectory/columbus-and-central-ohio">
                      <h3 className="mb-2 mt-2 mt-xl-0 magazine-column-head magazine-col-directory">
                        Columbus & central Ohio
                      </h3>
                    </Link>
                    <h5>
                      Businesses and the Deals from the Columbus & central Ohio
                      Area.
                    </h5>
                    <div className="mb-3">
                      <button
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                          background: `transparent`,
                          border: `none`,
                        }}
                        type="button"
                        onClick={() => {
                          downloadFunc(Columbus);
                        }}
                      >
                        Find a Copy
                      </button>
                      <Link
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                        }}
                        to="/printdirectory/columbus-and-central-ohio"
                        className="pl-5"
                        rel="noreferrer noopener"
                      >
                        View Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-5 mb-md-5 magazine-column">
                <div className="row">
                  <div className="col-xl-5 directory-img-col">
                    <div className="directory-img">
                      <Link to="/printdirectory/dayton-springfield-and-the-miami-valley">
                        <img
                          src={dayton}
                          alt="Magazine Cover"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-7 heading-print-directory">
                    <Link to="/printdirectory/dayton-springfield-and-the-miami-valley">
                      <h3 className="mb-2 mt-2 mt-xl-0 magazine-column-head magazine-col-directory">
                        Dayton, Springfield, & the Miami Valley
                      </h3>
                    </Link>
                    <h5>
                      Businesses and the Deals from Dayton, Springfield, & the
                      Miami Valley Area.
                    </h5>
                    <div className="mb-3">
                      <button
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                          background: `transparent`,
                          border: `none`,
                        }}
                        type="button"
                        onClick={() => {
                          downloadFunc(Dayton);
                        }}
                      >
                        Find a Copy
                      </button>
                      <Link
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                        }}
                        to="/printdirectory/dayton-springfield-and-the-miami-valley"
                        className="pl-5"
                        rel="noreferrer noopener"
                      >
                        View Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5 mb-md-5 magazine-column">
                <div className="row">
                  <div className="col-xl-5 directory-img-col">
                    <div className="directory-img">
                      <Link to="/printdirectory/indianapolis-and-central-indiana">
                        <img
                          src={Indiana}
                          alt="Magazine Cover"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-7 heading-print-directory">
                    <Link to="/printdirectory/indianapolis-and-central-indiana">
                      <h3 className="mb-2 mt-2 mt-xl-0 magazine-column-head magazine-col-directory">
                        Indianapolis & central Indiana
                      </h3>
                    </Link>
                    <h5>
                      Businesses and the Deals from the Indianapolis & central
                      Indiana Area.
                    </h5>
                    <div className="mb-3">
                      <button
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                          background: `transparent`,
                          border: `none`,
                        }}
                        type="button"
                        onClick={() => {
                          downloadFunc(Indianapolis);
                        }}
                      >
                        Find a Copy
                      </button>
                      <Link
                        style={{
                          position: `relative`,
                          color: `#005D9D`,
                          fontSize: `20px`,
                          textAlign: `left`,
                          textDecoration: `underline`,
                        }}
                        to="/printdirectory/indianapolis-and-central-indiana"
                        className="pl-5"
                        rel="noreferrer noopener"
                      >
                        View Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PrintDistribution />
      </main>
    </div>
  );
}
